.container-template {
    margin-left: 250px;
    transition: all .5s ease;
    height: 100%;
}

.view-template {
    padding: 8px;
    padding-top: 80px;
    height: 90%;
}

@media(max-width: 768px) {
    .container-template {
        margin-left: 0;
    }
}

@media(max-width: 900px){
    .container-template {
        margin-left: 220px;
    }
}

@media(max-width: 600px){
    .container-template {
        margin-left: 0;
    }
}