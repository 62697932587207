.side-area {
    position: fixed;
    display: block;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: var(--primary);
    transition: all .5s ease;
    z-index: 777;
}

.header-menu {
    min-height: 64px;
    background-color: var(--opacity);
}

@media(max-width: 600px){
    .side-area {
        left: -250px;
    }
}

.menu-item-sidebar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    color: var(--white);
    font-size: 20px;
    line-height: 50px;
    min-height: 50px;
    box-sizing: border-box;
    background-color: transparent;
    transition: 0.6s;
    cursor: pointer;
}

.menu-item-sidebar:hover {
    padding-left: 20px; 
}

.menu-submenu {
    background-color: var(--header);
    padding-left: 26px;
}

.menu-active {
    background-color: var(--red);
}

.link-menu-sidebar {
    display: flex;
    align-items: center;
}

.link-menu-sidebar svg {
    margin-right: 8px;
    font-size: 25px;
}

.link-menu-sidebar h6 {
    font-size: 16px;
}

@media(max-width: 900px){
    .side-area {
        width: 220px;
    }
}