.containerCardDestak {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    word-wrap: break-word;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 30px;
    border-radius: 6px;
    margin-bottom: 30px;
    flex-direction: column;
    height: 100p;
}

.containerCardDestak .initCard {
    text-align: right;
    background: transparent;
    box-shadow: none;
    color: #000;
    margin: 0 15px;
    padding: 0;
    position: relative;
}

.containerCardDestak .initCard .containerIcon {
    color: #fff;
    float: left;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    border-radius: 3px;
    background-color: #999;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 0, 0,.4);
}

.containerCardDestak .initCard p {
    color: #000000;
    margin: 0;
    font-size: 11pt;
    margin-top: 0;
    padding-top: 10px;
    margin-bottom: 0;
}

.containerCardDestak .initCard h3 {
    color: #3C4858;
    margin-top: 0px;
    min-height: auto;
    font-weight: 300;
    margin-bottom: 3px;
    text-decoration: none;
    font-size: 26pt;
}