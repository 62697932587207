* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #000;
  background-color: #fff;
}

html, body, #app {
  height: 100%;
}

a {
  text-decoration: none;
}

:root {
  --primary: #091114;
  --secondary: #00698f;
  --red: #a71a1a;
  --success: #00a65a;
  --purple: #8c00ff;
  --blue: #1500ff;
  --cinze: #7b7b7b;
  --warning: #ede436;
  --white: #fff;
  --opacity: #f0f0f0;
  --black: #000;
  --undraw: #2f2e41;
  --header: #1e2b30;
}
