.single-input{
    position: relative;
    margin: 10px 0;
}
.single-input label{
    position: absolute;
    bottom: 8px;
    left: 0;
    color: rgb(150,150,150);
    cursor: text;
    transition: 0.5s ease-in-out;
    transform: translateY(-24px);
    font-size: 12px;
    color: rgb(0, 0, 0);
}

.input{
    width: 100%;
    padding: 5px;
    outline: 0;
    font-size: 16px;
    color: rgb(0, 0, 0);
    transition: 0.5s ease-in-out;
}